<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title class="">
            {{ title }}
         </v-card-title>
         <v-card-text>
            <form>
               <v-text-field v-model="nombre" label="Nombre" :error-messages="nombreErrors" @input="$v.nombre.$touch()"
                  @blur="$v.nombre.$touch()" color="secondary" />
               <v-text-field v-model="clave_producto" label="Clave Producto" :error-messages="clave_productoErrors"
                  @input="$v.clave_producto.$touch()" @blur="$v.clave_producto.$touch()" color="secondary" />
               <v-select v-model="modulo" :items="['Sidev', 'Aplicaciones NG']" label="Modulo" color="secondary"
                  :error-messages="moduloErrors" @change="$v.modulo.$touch()" />
            </form>
         </v-card-text>
         <v-card-actions class="justify-end pb-4">
            <v-btn @click="close" elevation="24" large>Cancelar</v-btn>
            <v-btn :loading="isloading" @click="save" elevation="24" large>Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
   props: {
      isEdit: Boolean,
   },
   validations: {
      nombre: {
         required,
         minLength: minLength(5),
         maxLength: maxLength(50),
      },
      clave_producto: {
         required,
         minLength: minLength(5),
         maxLength: maxLength(50),
      },
      modulo: {
         required,
      },
   },
   data: function () {
      return {
         producto_id: null,
         nombre: "",
         clave_producto: "",
         modulo: "",

         dialog: false,
         isloading: false,
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertProducto", {
                     nombre: this.nombre,
                     clave_producto: this.clave_producto,
                     modulo: this.modulo,
                  });
               } else {
                  res = await this.$store.dispatch("updateProducto", {
                     producto_id: this.producto_id,
                     nombre: this.nombre,
                     clave_producto: this.clave_producto,
                     modulo: this.modulo,
                  });
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "Validacion";
            }
         } catch (error) {
            this.$refs.error_noti.Show(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.dialog = true;
         this.isloading = false;
         this.producto_id = i.producto_id;
         this.nombre = i.nombre;
         this.clave_producto = i.clave_producto;
         this.modulo = i.modulo;
      },
   },
   computed: {
      title() {
         if (this.isEdit) {
            return "Editar Producto";
         } else {
            return "Crear Producto";
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.minLength && errors.push("Debe contener mínimo 5 caracteres");
         !this.$v.nombre.maxLength && errors.push("Debe contener máximo 50 caracteres");
         return errors;
      },
      clave_productoErrors() {
         const errors = [];
         if (!this.$v.clave_producto.$dirty) return errors;
         !this.$v.clave_producto.required && errors.push("Requerida");
         !this.$v.clave_producto.minLength && errors.push("Debe contener mínimo 5 caracteres");
         !this.$v.clave_producto.maxLength && errors.push("Debe contener máximo 50 caracteres");
         return errors;
      },
      moduloErrors() {
         const errors = [];
         if (!this.$v.modulo.$dirty) return errors;
         !this.$v.modulo.required && errors.push("El módulo es requerido");
         return errors;
      },
   },
};
</script>
